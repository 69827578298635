import React from 'react';
import { Trans } from 'react-i18next';

import { LOADING_OPTIONS, LinkWrapper, Text } from '@components';

import { META_IMAGE } from '@shared/constants/constants';
import { getFetchedImage } from '@shared/image-helpers';

import footerImage from '@images/affiliate/footer-image.png';
import footerImageTablet from '@images/affiliate/footer-image-tablet.png';
import footerImageTabletTop from '@images/affiliate/footer-image-tablet-top.png';
import footerImageMobile from '@images/affiliate/footer-image-mobile.png';
import footerImageMobileTop from '@images/affiliate/footer-image-mobile-top.png';
// Feature images
import TrustedBrand from '@images/affiliate/trusted-brand.svg';
import DedicatedManager from '@images/affiliate/dedicated-account-manager.svg';
import FullSupport from '@images/affiliate/full-support.svg';
import GenerousCommissions from '@images/affiliate/generous-commissions.svg';
// Tools images
import Tracking from '@images/affiliate/tracking.svg';
import Assets from '@images/affiliate/assets.svg';
import Coupons from '@images/affiliate/coupons.svg';
// Steps images
import SignUp from '@images/affiliate/sign-up.svg';
import RetrieveLink from '@images/affiliate/retrieve-link.svg';
import StartEarning from '@images/affiliate/start-earning.svg';
import techRadar from '@images/TechRadar.svg';
import bestValueVPN from '@images/best-value-vpn.svg';
import proPrivacy from '@images/pro-privacy.png';

const BoldLinkWrapper = ({ link, children }) => (
  <LinkWrapper link={link}>
    <Text.Body1Strong as="span">{children}</Text.Body1Strong>
  </LinkWrapper>
);

const LINKS = {
  signUp: 'https://atlasvpn.hasoffers.com/users/signup/',
  signIn: 'https://atlasvpn.hasoffers.com',
};

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('affiliate:metaData.title'),
    description: t('affiliate:metaData.description'),
    image: META_IMAGE,
  }),
  headerCta: {
    text: ({ t }) => ({
      title: t('affiliate:headerCta.title'),
      description: t('affiliate:headerCta.description'),
      buttons: [
        {
          title: t('common:actions.becomeAffiliate'),
          link: LINKS.signUp,
        },
        {
          title: t('common:actions.affiliateLogin'),
          link: LINKS.signIn,
          buttonProps: {
            variant: 'outlined',
          },
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: getFetchedImage(images, 'affiliate/header-image-mobile.png'),
        alt: 'affiliate header',
        loading: LOADING_OPTIONS.eager,
      },
      tablet: {
        src: getFetchedImage(images, 'affiliate/header-image-tablet.png'),
        alt: 'affiliate header',
        loading: LOADING_OPTIONS.eager,
      },
      desktop: {
        src: getFetchedImage(images, 'affiliate/header-image.png'),
        alt: 'affiliate header',
        loading: LOADING_OPTIONS.eager,
        sizes: '3000px',
        position: {
          top: -120, // To go behind navbar
          height: 830,
          maxWidth: 3000,
        },
      },
    }),
  },
  features: (t) => ({
    title: t('affiliate:featureCards.title'),
    description: t('affiliate:featureCards.description'),
    items: [
      {
        title: t('affiliate:featureCards.trustedBrand.title'),
        description: t('affiliate:featureCards.trustedBrand.description'),
        image: TrustedBrand,
      },
      {
        title: t('affiliate:featureCards.dedicatedManager.title'),
        description: t('affiliate:featureCards.dedicatedManager.description'),
        image: DedicatedManager,
      },
      {
        title: t('affiliate:featureCards.fullSupport.title'),
        description: t('affiliate:featureCards.fullSupport.description'),
        image: FullSupport,
      },
      {
        title: t('affiliate:featureCards.generousCommissions.title'),
        description: t('affiliate:featureCards.generousCommissions.description'),
        image: GenerousCommissions,
      },
    ],
  }),
  earnings: (t) => ({
    title: t('affiliate:earnings.title'),
    description: t('affiliate:earnings.description'),
    tableData: {
      header: ['', t('affiliate:earnings.newSignUps'), t('affiliate:earnings.renewals')],
      data: [
        [
          t('affiliate:earnings.1monthOffer'),
          <Text.Body2 as="span" fontSize="inherit" key={1}>
            {t('affiliate:earnings.revShare', { percentage: '60' })}
          </Text.Body2>,
          <Text.Body2 as="span" fontSize="inherit" key={2}>
            {t('affiliate:earnings.revShare', { percentage: '30' })}
          </Text.Body2>,
        ],
        [
          t('affiliate:earnings.1yearOffer'),
          <Text.Body2 as="span" fontSize="inherit" key={1}>
            {t('affiliate:earnings.revShare', { percentage: '60' })}
          </Text.Body2>,
          <Text.Body2 as="span" fontSize="inherit" key={2}>
            {t('affiliate:earnings.revShare', { percentage: '30' })}
          </Text.Body2>,
        ],
        [
          t('affiliate:earnings.3yearOffer'),
          <Text.Body2 as="span" fontSize="inherit" key={1}>
            {t('affiliate:earnings.revShare', { percentage: '60' })}
          </Text.Body2>,
          <Text.Body2 as="span" fontSize="inherit" key={2}>
            {t('affiliate:earnings.revShare', { percentage: '30' })}
          </Text.Body2>,
        ],
      ],
    },
    button: {
      title: t('common:actions.becomeAffiliate'),
      link: LINKS.signUp,
    },
  }),
  tools: (t) => ({
    title: t('affiliate:tools.title'),
    items: [
      {
        title: t('affiliate:tools.tracking.title'),
        description: t('affiliate:tools.tracking.description'),
        image: Tracking,
      },
      {
        title: t('affiliate:tools.assets.title'),
        description: t('affiliate:tools.assets.description'),
        image: Assets,
      },
      {
        title: t('affiliate:tools.coupons.title'),
        description: t('affiliate:tools.coupons.description'),
        image: Coupons,
      },
    ],
  }),
  reviews: (t) => ({
    title: t('affiliate:reviews.title'),
    items: [
      {
        logoImage: {
          src: techRadar,
          alt: 'TechRadar logo',
        },
        comment: 'Capable and powerful VPN platform ticking most industry boxes.',
      },
      {
        logoImage: {
          src: bestValueVPN,
          alt: 'Best Value VPN logo',
        },
        comment: 'Remarkably good considering the low cost of a subscription.',
      },
      {
        logoImage: {
          src: proPrivacy,
          alt: 'ProPrivacy logo',
        },
        comment: 'One of the best VPN services available on the market.',
      },
    ],
  }),
  steps: (t) => ({
    title: t('affiliate:stepCards.title'),
    items: [
      {
        title: t('affiliate:stepCards.signUp.title'),
        description: t('affiliate:stepCards.signUp.description'),
        image: SignUp,
      },
      {
        title: t('affiliate:stepCards.retrieveLink.title'),
        description: t('affiliate:stepCards.retrieveLink.description'),
        image: RetrieveLink,
      },
      {
        title: t('affiliate:stepCards.startEarning.title'),
        description: t('affiliate:stepCards.startEarning.description'),
        image: StartEarning,
      },
    ],
    button: {
      title: t('common:actions.joinNow'),
      link: LINKS.signUp,
    },
  }),
  footerCta: {
    text: ({ t }) => ({
      title: t('affiliate:footerCta.title'),
      description: t('affiliate:footerCta.description'),
      comment: (
        <Trans
          i18nKey="affiliate:footerCta.reachOut"
          components={[<BoldLinkWrapper link="mailto:affiliates@atlasvpn.com" key={0} />]}
        />
      ),
      buttons: [
        {
          title: t('common:actions.becomeAffiliate'),
          link: LINKS.signUp,
        },
      ],
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'affiliate footer',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'affiliate footer',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'affiliate footer',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'affiliate footer',
      },
      desktop: {
        src: footerImage,
        alt: 'affiliate footer',
        position: {
          bottom: 0,
          height: 500,
          maxWidth: 2900,
        },
      },
    }),
  },
});
