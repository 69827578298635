import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { Box, Button } from '@core';
import {
  Card,
  Layout,
  LinkWrapper,
  SEO,
  SectionContainerSC as SectionContainer,
  Table,
  Text,
} from '@components';
import { CTASection, CardsBlockSection } from '@containers';
import { getFilteredNavItems } from '@shared/constants/constants';
import { PAGE_DATA } from '@shared/constants/pages/affiliate';

const AffiliateLandingPage = ({ location, data }) => {
  const { metaData, headerCta, features, earnings, tools, reviews, steps, footerCta } = PAGE_DATA({
    images: data.allFile.edges,
  });

  const { t } = useTranslation();

  const earningsData = earnings(t);

  return (
    <Layout
      location={location}
      navProps={{
        navItems: getFilteredNavItems({ t }),
        initialBackgroundColor: { _: 'white', lg: 'transparent' },
      }}
      wrapperProps={{ backgroundColor: 'unset' }}
    >
      <SEO {...metaData(t)} />
      <CTASection
        data={headerCta}
        sectionProps={{
          wrapperProps: { py: { lg: 0 } },
        }}
        contentProps={{
          pt: 130,
          pb: 320,
        }}
      />
      <CardsBlockSection
        {...features(t)}
        itemsOnDesktop={2}
        itemComponent={Card.Feature}
        sectionProps={{ wrapperProps: { pt: { _: 20, md: 40 }, pb: { _: 20, md: 40 } } }}
      />
      <SectionContainer
        data-section-id="Earnings"
        {...earningsData}
        wrapperProps={{ pt: { _: 80, md: 120 }, pb: { _: 20, md: 40 } }}
      >
        <Box overflowX="scroll">
          <Box maxWidth={920} mx="auto" px={24}>
            <Table>
              <Table.Head>
                <Table.Row>
                  {earningsData.tableData.header.map((colData, i) => (
                    <Table.HeaderCell
                      key={i}
                      textAlign={i > 0 ? 'center' : 'unset'}
                      spacing={{ px: { _: 8, md: 24 } }}
                    >
                      {colData}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {earningsData.tableData.data.map((rowData, rowIndex) => (
                  <Table.Row key={rowIndex} isOddRow={!(rowIndex % 2)}>
                    {rowData.map((colData, colIndex) => (
                      <Table.Cell
                        key={colIndex}
                        textAlign={colIndex > 0 ? 'center' : 'unset'}
                        isOddRow={!(rowIndex % 2)}
                        spacing={{ px: { _: 8, md: 24 } }}
                      >
                        {colData}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Box>
        </Box>
        <Box mt={40} display="flex" justifyContent="center">
          <Box minWidth={300}>
            <LinkWrapper link={earningsData.button.link} target="_blank" withParams>
              <Button size="large" fullWidth>
                {earningsData.button.title}
              </Button>
            </LinkWrapper>
          </Box>
        </Box>
      </SectionContainer>
      <CardsBlockSection
        {...tools(t)}
        itemsOnDesktop={3}
        itemComponent={Card.Feature}
        sectionProps={{ wrapperProps: { pt: { _: 80, md: 120 }, pb: { _: 20, md: 40 } } }}
      />
      <CardsBlockSection
        {...reviews(t)}
        itemsOnDesktop={3}
        itemComponent={Card.Mention}
        sectionProps={{ wrapperProps: { pt: { _: 80, md: 120 }, pb: { _: 20, md: 40 } } }}
      />
      <CardsBlockSection
        {...steps(t)}
        itemsOnDesktop={3}
        itemComponent={Card.Feature}
        sectionProps={{ wrapperProps: { pt: { _: 80, md: 120 }, pb: { _: 20, md: 40 } } }}
      />
      <CTASection
        data={footerCta}
        titleComponent={Text.Heading2}
        commentComponent={Text.Body1}
        sectionProps={{
          wrapperProps: { pb: 0, pt: { _: 40, lg: 120 } },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          py: 100,
        }}
      />
    </Layout>
  );
};

export default AffiliateLandingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation", "affiliate"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "affiliate/header-image.png"
            "affiliate/header-image-tablet.png"
            "affiliate/header-image-mobile.png"
          ]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
